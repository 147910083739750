import {OrdPermissionTreeDataNode} from "@ord-core/config/permissions/tree-data/permission.tree.data";
import {PERMISSION_APP} from "@ord-core/config/permissions";

export const ADMIN_PERMISSION_TREE_DATA: OrdPermissionTreeDataNode[] = [
    {
        name: PERMISSION_APP.admin.user,
        actions: ['Create', 'Update', 'Remove', 'ResetPassword', 'AssignRole', 'LoginPasswordless']
    },
    {
        name: PERMISSION_APP.admin.role,
        actions: ['Create', 'Update', 'Remove', 'SetPermission']
    },
    {
        name: PERMISSION_APP.admin.tenant,
        actions: ['Create', 'Update', 'Remove', 'ViewUserList']
    },
    {
        name: PERMISSION_APP.admin.setting,
        actions: ['GetPaged', 'SetValue']
    }
];
