import baseHttpApi from "@ord-core/service-proxies/httpService";
import {AppBootstrapDto} from "@ord-core/service-proxies/session/dto";


class AppInformationApiService {
    public async getBoostrap(): Promise<AppBootstrapDto> {
        const result = await baseHttpApi.get('/api/auth-plugin/information/get-boostrap');
        return result.data as AppBootstrapDto;
    }
}

export default new AppInformationApiService();
