import {
    axios,
    basePath,
    CommonResultDtoOfBoolean,
    CommonResultDtoOfTenantDto,
    getConfigs,
    IRequestConfig,
    IRequestOptions,
    OrdPagedRequestDto,
    PagedResultDtoOfTenantDto,
    PagedResultDtoOfUserDto,
    ResetPasswordCommand,
    TenantCreateCommand,
    TenantDto,
    UserGetPagedQuery
} from './index.defs';

export class TenantService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getPaged(
    params: {
      /** requestBody */
      body?: OrdPagedRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PagedResultDtoOfTenantDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/tenant/get-paged';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportPagedResult(
    params: {
      /** requestBody */
      body?: OrdPagedRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/tenant/export-paged-result';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TenantCreateCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultDtoOfTenantDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/tenant/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TenantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultDtoOfTenantDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/tenant/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      tenantId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultDtoOfTenantDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/tenant/remove/{tenantId}';
      url = url.replace('{tenantId}', params['tenantId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserList(
    params: {
      /**  */
      tenantId: string;
      /** requestBody */
      body?: UserGetPagedQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PagedResultDtoOfUserDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/tenant/get-user-list/{tenantId}';
      url = url.replace('{tenantId}', params['tenantId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPasswordUserTenant(
    params: {
      /**  */
      tenantId: string;
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultDtoOfBoolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/tenant/reset-password-user-tenant/{tenantId}';
      url = url.replace('{tenantId}', params['tenantId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
