import {Bounce, toast, ToastOptions} from "react-toastify";
import {l} from "@ord-core/language/lang.utils";
import {createConfirmation} from "react-confirm";
import OrdConfirm, {OrdConfirmProps} from "@ord-components/confirm/OrdConfirm";

declare var ord: any;

class UiUtils {

    private BASE_TOAST: ToastOptions<any> = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce
    }
    showSuccess = (content?: string) => {
        toast.success(content, this.BASE_TOAST);
    };
    showError = (content?: string) => {
        toast.error(content, this.BASE_TOAST);
    };

    showCatchError(error: any) {

    }

    showCommonValidateForm() {
        this.showError(l.transCommon('formInvalid.common'));
    }

    setBusy() {
        ord.ui.setBusy();
    }

    clearBusy() {
        ord.ui.clearBusy();
    }

    showConfirm(input: OrdConfirmProps) {
        const confirm = createConfirmation(OrdConfirm);
        confirm({
            ...input
        }).then();
    }
}

export default new UiUtils();
