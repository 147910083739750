import {TinhDto} from "@api/index.defs";
import {TinhService} from "@api/TinhService";
import {CommonListStore, ICreateOrUpdateModal} from "@ord-core/base/CommonListStore";

class ProvinceListStore extends CommonListStore<TinhDto> {
    getNamespaceLocale(): string {
        return 'province'
    }

    apiService() {
        return TinhService;
    }

    getInitModal(): ICreateOrUpdateModal {
        return {
            visible: false,
            width: 550
        };
    }

    override initFormValueWhenOpenModal(): any {
        return {
            // ma: 'abc'
        }
    }

    getListColumnNameExcel() {
        return ['stt', 'ma', 'ten', 'status'];
    }
}

export default ProvinceListStore;
