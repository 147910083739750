import './AppSidebarLayout.scss';
import React, {useEffect, useState} from 'react';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {Button, Layout, Menu, theme} from 'antd';
import {Outlet, useLocation} from "react-router-dom";
import {useStore} from "@ord-store/index";
import MenuUtils from "@ord-core/layout/menu.utils";
import HeaderRight from "@ord-core/layout/Header/HeaderRight";
import {BrowserView, MobileView} from 'react-device-detect';
import MobileLayout from "@ord-core/layout/MobileLayout";

const {Header, Sider, Content, Footer} = Layout;
const AppSidebarLayout: React.FC = () => {
    const location = useLocation();
    const selectedKeys = [location.pathname];
    const [collapsed, setCollapsed] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>();
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    const {sessionStore} = useStore();

    useEffect(() => {
        setMenuItems(MenuUtils.getMenuItems(sessionStore.appSession));
    }, [sessionStore]);
    useEffect(() => {
        setDefaultOpenKeys(MenuUtils.getOpenKeys(selectedKeys));
    }, [location.pathname]);

    return (
        <>
            <BrowserView>
                <Layout style={{minHeight: '100vh'}}>
                    <Sider trigger={null} collapsible collapsed={collapsed} width={260}>
                        <div className="logo-vertical">
                            {collapsed ? <img id="logo-img"
                                              className="w-[200px]"
                                              src="/images/logo/logo-fold.png"
                                              alt="Logo"/> :
                                <img id="logo-img" className="ps-[30px] w-[200px]" src="/images/logo/logo-white.png"
                                     alt="Logo"/>}

                        </div>
                        {
                            defaultOpenKeys && <Menu
                                theme="dark"
                                mode="inline"
                                items={menuItems}
                                selectedKeys={selectedKeys}
                                defaultOpenKeys={defaultOpenKeys}
                            />
                        }

                    </Sider>
                    <Layout>
                        <Header style={{
                            background: '#fff',
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            <HeaderRight/>

                        </Header>
                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            <Outlet/>
                        </Content>
                        <Footer style={{textAlign: 'center'}}>Orenda</Footer>
                    </Layout>
                </Layout>
            </BrowserView>
            <MobileView>
                <MobileLayout>
                    <Outlet/>
                </MobileLayout>
            </MobileView>
        </>


    );
};

export default AppSidebarLayout;
