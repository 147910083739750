import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import appInformationApiService from "@ord-core/service-proxies/session/appInformationApiService";
import {Provider, rootStore} from "@ord-store/index";
import {I18nextProvider} from 'react-i18next';
import './i18n';
import i18n from "i18next";
import {serviceOptions} from "@api/index.defs";
import httpService from "@ord-core/service-proxies/httpService";

serviceOptions.axios = httpService;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
appInformationApiService.getBoostrap().then(data => {
    root.render(
        <I18nextProvider i18n={i18n}>
            <Provider value={rootStore}>
                <App boostrapResultDto={data}/>
            </Provider>
        </I18nextProvider>
    );

}).catch((error) => {
    //  alert(error);
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

