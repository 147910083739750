import SessionStore from './sessionStore';
import {createContext, useContext} from "react";
import UserListStore from "@ord-store/admin/userListStore";
import ProvinceListStore from "@ord-store/master-data/provinceListStore";
import UiStore from "@ord-store/uiStore";
import SelectDataSourceStore from "@ord-store/selectDataSourceStore";
import EntityModalStore from "@ord-store/entityModalStore";
import RoleListStore from "@ord-store/admin/roleListStore";
import TenantListStore from "@ord-store/admin/tenantListStore";
import SettingListStore from "@ord-store/admin/settingListStore";

export const rootStore = {
    sessionStore: new SessionStore(),
    userListStore: new UserListStore(),
    roleListStore: new RoleListStore(),
    settingStore: new SettingListStore(),
    tenantListStore: new TenantListStore(),
    provinceListStore: new ProvinceListStore(),
    uiStore: new UiStore(),
    selectDataSourceStore: new SelectDataSourceStore(),
    entityModalStore: new EntityModalStore(),

};
export type TRootStore = typeof rootStore;
const RootStoreContext = createContext<null | TRootStore>(null);


// Tạo ra provider để cung cấp store cho toàn bộ app
// dung trong file index.tsx
export const Provider = RootStoreContext.Provider;

/** tra lai store, chi dung o function component */
export function useStore() {
    /** store này sẽ chứa toàn bộ data */
    const store = useContext(RootStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}
