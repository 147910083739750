import React, {useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {observer} from "mobx-react";
import {useStore} from "@ord-store/index";
import {checkPermissionUser} from "@ord-core/utils/auth.utils";

interface IProtectedRouteProp {
    children: any,
    permission?: string
}

const ProtectedRoute = (prop: IProtectedRouteProp) => {
    const {sessionStore} = useStore();
    let location = useLocation();
    useEffect(() => {
        console.log(location.pathname, prop.permission);
    }, []);

    if (sessionStore.appSession?.isLogined !== true) {
        return <Navigate to="/auth/login"/>;
    }
    return checkPermissionUser(sessionStore.appSession, prop.permission) ? prop.children :
        <Navigate to="/not-permission"/>;
}

export default observer(ProtectedRoute);
