import {action, computed, observable} from 'mobx';
import {AppBootstrapDto} from "@ord-core/service-proxies/session/dto";

class SessionStore {
    @observable appSession: AppBootstrapDto = new AppBootstrapDto();

    @computed get isLogined() {
        return this.appSession.isLogined;
    }

    @computed get permissions() {
        return this.appSession?.user?.listPermission || [];
    }

    @computed get userId() {
        return this.appSession?.user?.id || null;
    }

    @action setSession(session: AppBootstrapDto) {
        if (session?.user) {
            session.permissionGranted = {};
            if (session?.user?.listPermission) {
                session.user.listPermission.forEach(namePermission => {
                    session.permissionGranted[namePermission] = true;
                });
            }
        }
        this.appSession = session;
    }
}

export default SessionStore;
