import {createBrowserRouter} from "react-router-dom";
import ProtectedRoute from "@ord-core/layout/ProtectedRoute";
import React, {Suspense} from "react";
import AuthLayout from "@ord-core/layout/AuthLayout";
import {authRoutes} from "./authRouter";
import {appRouters} from "./appRouters";
import AppSidebarLayout from "@ord-core/layout/AppSidebarLayout";
import NotFoundPage from "@ord-components/common/NotFoundPage";

export const ROOT_ROUTER = createBrowserRouter([
    {
        path: "/",
        element: <AppSidebarLayout/>,
        children: [...appRouters].map(it => {
            return {
                index: it?.index,
                path: it.path,
                async lazy() {
                    const Component = it.lazyComponent;
                    return {
                        element:
                            <Suspense>
                                <ProtectedRoute permission={it?.permission}>
                                    <Component/>
                                </ProtectedRoute>
                            </Suspense>

                    };
                },
            }
        }),
        errorElement: <NotFoundPage/>,
    },
    {
        path: "/auth",
        element: <AuthLayout/>,
        children: [...authRoutes].map(it => {
            return {
                index: it?.index,
                path: it.path,
                async lazy() {
                    const Component = it.lazyComponent;
                    return {
                        element: <Suspense>
                            <Component/>
                        </Suspense>
                    };
                },
            }
        }),
        errorElement: <NotFoundPage/>,
    }
]);
