import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, CheckboxProps, Space} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {debounce} from "lodash";

interface IProp {
    hasAddNewContinue?: boolean,
    isAddNewContinue?: boolean,
    isAddNewContinueChange?: (checked: boolean) => void,
    onCancel: () => void;
    onOk: () => void;
    okBtn?: ReactNode;
    cancelBtn?: ReactNode;
    hiddenOk?: boolean;
}

export const FooterCrudModal = (props: IProp) => {
    const {t} = useTranslation('common');
    const onChange: CheckboxProps['onChange'] = (e) => {
        if (props.isAddNewContinueChange) {
            props.isAddNewContinueChange(e.target.checked);
        }
    };
    const onOkClick = () => {
        props.onOk();
    }
    const debouncedClick = debounce(onOkClick, 250);
    return (
        <div
            className="flex flex-wrap items-center justify-between  max-sm:flex-col">
            <div>
                {
                    props?.hasAddNewContinue &&
                    <Checkbox checked={props.isAddNewContinue}
                              onChange={onChange}>
                        {t('addNewContinue')}
                    </Checkbox>
                }
            </div>


            <div className="flex items-center">
                <Button className='me-2' onClick={props.onCancel}>
                    {
                        props?.cancelBtn || (
                            <Space.Compact><Space><CloseOutlined className="me-1"/></Space>{t('cancelModal')}
                            </Space.Compact>)
                    }

                </Button>
                {
                    props?.hiddenOk !== true && <Button type='primary' onClick={debouncedClick}>
                        {
                            props?.okBtn || (
                                <Space.Compact> <Space><SaveOutlined className="me-1"/></Space>{t('saveModal')}</Space.Compact>)
                        }

                    </Button>
                }

            </div>
        </div>
    );
}
