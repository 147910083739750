import {action, computed, observable} from "mobx";
import {FormInstance} from "antd";
import UiUtils from "@ord-core/utils/ui.utils";
import {CommonCrudApi} from "@ord-core/base/CommonCrudApi";
import ServiceProxyUtils from "@ord-core/utils/service-proxy.utils";
import {l, LWithNs} from "@ord-core/language/lang.utils";
import FileSaver from "file-saver";


export interface ICreateOrUpdateModal {
    visible?: boolean;
    entityData?: any;
    mode?: 'addNew' | 'update' | 'viewDetail';
    width: number;
}

export abstract class CommonListStore<TListItemDto> {
    private searchFormRef: FormInstance | undefined;
    @observable
    searchDataState: any = {};
    @observable
    refreshDataState = 0;

    @observable createOrUpdateModal: ICreateOrUpdateModal = {
        ...this.getInitModal()
    }
    @observable removeRecord: any = null

    @computed get entityFormDisable() {
        return this.createOrUpdateModal.mode === 'viewDetail';
    }

    @action
    setSearchFormRef(searchFormRef: FormInstance) {
        this.searchFormRef = searchFormRef;
    }

    @action searchData(data: any) {
        this.searchDataState = {...data}
    }

    @action
    async refreshGridData(mustPrePage = false) {
        this.refreshDataState = this.refreshDataState + 1;
    }

    @action
    openUpdateModal(entityData: any) {
        this.createOrUpdateModal.visible = true;
        this.createOrUpdateModal.entityData = entityData;
        this.createOrUpdateModal.mode = 'update';
    }

    @action
    openCreateModal() {
        this.createOrUpdateModal.visible = true;
        this.createOrUpdateModal.entityData = null;
        this.createOrUpdateModal.mode = 'addNew';
    }

    @action
    openViewDetailModal(entityData: any) {
        this.createOrUpdateModal.visible = true;
        this.createOrUpdateModal.entityData = entityData;
        this.createOrUpdateModal.mode = 'viewDetail';
    }

    @action
    closeModal(mustRefreshGridData: boolean = false) {
        this.createOrUpdateModal.visible = false;
        this.createOrUpdateModal.entityData = null;
        if (mustRefreshGridData) {
            this.refreshGridData().then();
        }
    }

    @action
    openRemoveById(removeRecord: any) {
        this.removeRecord = {
            ...removeRecord
        };
    }

    @action
    closeRemoveById() {
        this.removeRecord = null;
    }

    initFormValueWhenOpenModal(): any {
        return null;
    }

    @action
    async exportExcelPagedResult() {
        UiUtils.setBusy();
        try {
            const prm = this.searchFormRef?.getFieldsValue() || {};
            const body = {
                ...prm,
                export: {
                    title: l.trans(this.getNamespaceLocale() + '.fileExcel.Title', null),
                    columnNames: this.getListColumnNameExcel().map(it => {
                        if (it.toLowerCase() === 'stt') {
                            return l.transCommon(it, null)
                        }
                        return l.trans(this.getNamespaceLocale() + '.' + it, null)
                    })
                }
            }
            const resultBlob = await this.exportPaged(body);
            const fileName = l.trans(this.getNamespaceLocale() + '.fileExcel.FileName', null);
            FileSaver.saveAs(resultBlob, fileName);
        } catch {

        } finally {
            UiUtils.clearBusy();
        }
    }

    async beforeSaveEntity(input: any, isAddNew: boolean) {
        return input;
    }

    async createEntity(input: any) {
        const body = await this.beforeSaveEntity(input, true);
        const result = await this.apiService().createOrUpdate({
            body: body
        }, {});
        ServiceProxyUtils.notifyErrorResultApi(result, this.getNamespaceLocale(), input);
        return result.isSuccessful;
    }

    async updateEntity(input: any) {
        const body = await this.beforeSaveEntity(input, true);
        const result = await this.apiService().createOrUpdate({
            body: body
        }, {});
        ServiceProxyUtils.notifyErrorResultApi(result, this.getNamespaceLocale(), input);
        return result.isSuccessful;
    }

    exportPaged(body: any): Promise<any> {
        if (!!this.apiService().exportPagedResult) {
            // @ts-ignore
            return this.apiService().exportPagedResult({
                body: body
            }, {
                responseType: 'blob',
            });
        }
        return new Promise(() => {

        });
    }

    @action
    async removeEntity() {
        UiUtils.setBusy();
        try {
            const result = await this.apiService().remove({
                removeId: this.removeRecord['id'] || null
            }, {});
            if (result.isSuccessful) {
                UiUtils.showSuccess(l.trans(this.getNamespaceLocale() + '.removeSuccess', this.removeRecord));
                await this.refreshGridData(true);
            } else {
                ServiceProxyUtils.notifyErrorResultApi(result, this.getNamespaceLocale(), this.removeRecord);
            }
        } catch {

        } finally {
            this.closeRemoveById();
            UiUtils.clearBusy();
        }
    }

    tran = new LWithNs(this.getNamespaceLocale());

    abstract getNamespaceLocale(): string;

    abstract apiService(): CommonCrudApi<TListItemDto>;

    abstract getInitModal(): ICreateOrUpdateModal;

    abstract getListColumnNameExcel(): string[];
}
