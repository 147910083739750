import {action, observable} from 'mobx';
import {IOrdSelectOption} from "@ord-components/forms/select/OrdSelect";

class SelectDataSourceStore {
    lock: any = {};
    @observable dataOptions: any = {};

    @action
    async getOptions(key: string, optionsFunc: () => Promise<IOrdSelectOption[]>) {
        if (this.dataOptions[key] && this.dataOptions[key]?.length > 0) {
            return;
        }
        if (this.lock[key]) {
            return;
        }
        this.lock[key] = true;
        try {
            this.dataOptions[key] = await optionsFunc();
        } catch {

        } finally {
            this.lock[key] = false;
        }

    }
}

export default SelectDataSourceStore;
