import {Form, FormInstance, Modal} from "antd";
import UiUtils from "@ord-core/utils/ui.utils";
import React, {useEffect, useState} from "react";
import {FooterCrudModal} from "@ord-components/crud/FooterCrudModal";
import {ModalConfig} from "@ord-core/model/OrdEntityModalProp";
import {observer} from "mobx-react";

const EntityFormModal = (props: {
    children?: any,
    entity: any;
    modal?: ModalConfig;
    formContent?: (form: FormInstance) => React.ReactNode;
    onSave?: (formValues: any) => Promise<boolean>;
    onClose?: () => void;
}) => {
    const [entity, setEntity] = useState<any>();
    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [formContent, setFormContent] = useState<React.ReactNode>();

    useEffect(() => {
        if (!!props.entity && props.formContent) {
            setEntity(props.entity);
            setModalOpen(true);
            setFormContent(props.formContent(form));
        }
    }, [props.entity]);

    const onSave = async (formValues: any) => {
        if (props.onSave) {
            const result = await props.onSave({
                ...props.entity,
                ...formValues
            });
            if (result) {
                onCloseModal();
            }
        } else {
            onCloseModal();
        }

    }
    const onCloseModal = () => {
        setEntity(null);
        setModalOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }
    const onOkModal = () => {
        form.submit();
    }
    return (
        <>
            {props.entity &&
                <Modal
                    title={props?.modal?.title}
                    style={{top: 20, ...props?.modal?.style}}
                    width={props?.modal?.width || 550}
                    open={modalOpen}
                    onCancel={onCloseModal}
                    okButtonProps={{autoFocus: true, htmlType: 'submit'}}
                    destroyOnClose
                    maskClosable={false}
                    modalRender={(dom) => {
                        if (props?.modal?.ignoreFormEntity === true) {
                            return (<>{dom}</>);
                        }
                        return (
                            <Form
                                layout="vertical"
                                form={form}
                                autoComplete="off"
                                initialValues={entity}
                                clearOnDestroy
                                onFinish={onSave}
                                onFinishFailed={() => UiUtils.showCommonValidateForm()}
                            >
                                {dom}
                            </Form>
                        )
                    }}
                    footer={<FooterCrudModal hasAddNewContinue={false}
                                             okBtn={props?.modal?.okBtn}
                                             cancelBtn={props?.modal?.cancelBtn}
                                             hiddenOk={props?.modal?.hiddenOk}
                                             onOk={onOkModal}
                                             onCancel={() => onCloseModal()}/>}
                >
                    {formContent}
                    {props?.children}
                </Modal>}
        </>

    );
}
export default observer(EntityFormModal);
