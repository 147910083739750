import {ThemeConfig} from "antd";
import type {AliasToken} from "antd/es/theme/interface";
import type {ComponentToken as TableComponentToken} from "antd/es/table/style";

export const AntDesignConfigToken: Partial<AliasToken> =
    {}
export const AntDesignConfig: ThemeConfig = {
    token: {
        colorPrimary: '#2E3192',
        colorTextDisabled: '#000000FF',
        controlItemBgActiveDisabled: '#000000FF',
        colorError: '#F5413D',
        borderRadius: 6
    },
    components: {
        Button: {
            primaryShadow: 'none',
            dangerShadow: 'none',
            defaultShadow: 'none',
        },
        Input: {},
        Table: {
            cellPaddingBlock: 8,
            headerBg: '#BDBDC0',
            headerColor: '#212123'
        } as TableComponentToken
    }
}
