import React from "react";
import {Avatar, Dropdown, MenuProps, Space} from "antd";
import {DownOutlined, LogoutOutlined} from "@ant-design/icons";
import {useStore} from "@ord-store/index";
import {useTranslation} from "react-i18next";
import jwtUtils from "@ord-core/utils/jwt.utils";

const UserTopBar: React.FC = () => {
    const {sessionStore} = useStore();
    const {t} = useTranslation(['common']);
    const items: MenuProps['items'] = [
        {
            label: (<span className="text-blue-500 bold">
                {sessionStore.appSession.user?.name}
            </span>),
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: (<span><LogoutOutlined className="me-1"/> {t('logout')}</span>),
            key: '3',
            onClick: () => {
                jwtUtils.signOut();
            }
        },
    ];

    return (
        <Dropdown menu={{items}} placement="bottomRight">
            <Space className="cursor-pointer">
                <Avatar size="large" src="https://randomuser.me/api/portraits/men/41.jpg"/>
                <b>{sessionStore.appSession?.user?.userName}</b>
                <DownOutlined/>
            </Space>
        </Dropdown>
    );
}
export default UserTopBar;
