import {AppBootstrapDto} from "@ord-core/service-proxies/session/dto";
import complexMenu from "../../Router/complexMenu";
import {SideNavInterface} from "@ord-core/model/side-nav.type";
import {checkPermissionUser} from "@ord-core/utils/auth.utils";
import {Link} from "react-router-dom";
import React from "react";
import {l} from "@ord-core/language/lang.utils";

class MenuUtils {
    private _menu = [];
    private _isLoaded = false;
    private _flatMenu: any[] = [];

    getMenuItems(session: AppBootstrapDto) {
        if (this._isLoaded) {
            return this._menu;
        }
        // @ts-ignore
        const flatMenu = this.flattenMenu(complexMenu, null)
            .filter((s: SideNavInterface) => {
                return checkPermissionUser(session, s?.permission);
            }).map(it => {
                if (it.isLeaf && it.path) {
                    it.label = (<Link to={it.path}>{l.trans('menu.' + it.title)}</Link>);
                    it.key = it.path;
                } else {
                    it.label = l.trans('menu.' + it.title);
                }
                return {
                    ...it,
                }
            });
        this._flatMenu = flatMenu;
        this._menu = this.filterTree(this.buildTree(flatMenu)).filter((it: {
            isLeaf: boolean;
            children: string | any[];
        }) => {
            return it.isLeaf || it?.children?.length > 0;
        });
        this.convertToMenuItemAnt(this._menu);
        this._isLoaded = true;
        console.log(this._menu);
        return this._menu;

    }

    private flattenMenu(menu: SideNavInterface[], parentName?: string) {
        const result: SideNavInterface[] = [];

        function flatten(items: SideNavInterface[], parentName: string) {
            for (const item of items) {
                const {children, ...rest} = item;
                result.push({
                    ...rest,
                    parentName: parentName,
                    isLeaf: !(children && children?.length > 0),
                    key: item.title
                });
                if (children) {
                    flatten(children, rest.title);
                }
            }
        }

        // @ts-ignore
        flatten(menu, parentName);
        return result;
    }

    private buildTree(flatMenu: any[]) {
        const itemMap: any = {};
        const tree: any[] = [];

        flatMenu.forEach(item => {
            itemMap[item.title] = {...item};
        });

        flatMenu.forEach(item => {
            if (item.parentName) {
                if (!itemMap[item.parentName].children) {
                    itemMap[item.parentName].children = [];
                }
                itemMap[item.parentName].children.push(itemMap[item.title]);
            } else {
                tree.push(itemMap[item.title]);
            }
        });
        return tree;
    }

    private filterTree(data: any) {
        return data.reduce((acc: any, node: SideNavInterface) => {
            if (!(node.isLeaf === false && node.children && node.children.length === 0)) {
                const filteredNode = {...node};
                if (filteredNode.children && filteredNode.children.length > 0) {
                    filteredNode.children = this.filterTree(filteredNode.children);
                }
                acc.push(filteredNode);
            }
            return acc;
        }, []);
    }

    convertToMenuItemAnt(obj: any) {
        // Kiểm tra nếu obj là một mảng
        if (Array.isArray(obj)) {
            obj.forEach(item => this.convertToMenuItemAnt(item));
        }
        // Kiểm tra nếu obj là một đối tượng
        else if (typeof obj === 'object' && obj !== null) {
            delete obj.isLeaf; // Xóa thuộc tính isLeaf nếu tồn tại
            delete obj.parentName; // Xóa thuộc tính parentName nếu tồn tại
            Object.keys(obj).forEach(key => this.convertToMenuItemAnt(obj[key]));
        }
    }

    getOpenKeys(path: string[]) {
        if (this._flatMenu) {
            let f = this._flatMenu.find(x => x.path === path[0]);
            if (!f) {
                return [];
            }
            let rootNode = f.title;
            if (f.parentName) {
                for (let i = 0; i <= 6; i++) {
                    if (!f.parentName) {
                        return;
                    }
                    const parentNode = this._flatMenu.find(x => x.title === f.parentName);
                    if (parentNode) {
                        rootNode = parentNode.title;
                    }
                    if (parentNode && !!parentNode?.parentName) {
                        f = {...parentNode};
                    }
                }
            }
            return [rootNode];
        }
        return [];
    }
}

export default new MenuUtils();
