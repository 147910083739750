export class AppBootstrapDto {
    isLogined: boolean;
    user?: UserInformationDto;
    permissionGranted?: any;
    setting?: { [key: string]: any; } | undefined;

    constructor() {
        this.isLogined = false;
    }

    isGrantedPermission(permissionName: string) {
        if (!this.user) {
            return false;
        }
        if (this.user?.isSuperAdmin === true) {
            return true;
        }
        return this.permissionGranted && this.permissionGranted[permissionName] === true;
    }
}

export interface UserInformationDto {
    userName?: string | undefined;
    email?: string | undefined;
    phoneNumber?: string | undefined;
    name?: string | undefined;
    isActived?: boolean;
    level?: string | undefined;
    isLockoutEnabled?: boolean;
    dynamicInformation?: { [key: string]: string; } | undefined;
    listRoleId?: string[] | undefined;
    extendData?: any | undefined;
    mustChangePassword?: boolean;
    id?: string;
    tenantId?: string | undefined;
    tenantDto?: any | undefined;
    isSuperAdmin?: boolean;
    listPermission?: string[] | undefined;
}

export interface UserLoginInfoDto {
    name?: string;
    surname?: string;
    userName?: string;
    emailAddress?: string;
}
