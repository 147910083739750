import {JwtDto, LoginBody} from "@ord-core/service-proxies/auth/dto";
import {CommonResultDto} from "@ord-core/service-proxies/dto";
import axios from 'axios';
import JwtUtils from "@ord-core/utils/jwt.utils";

const qs = require('qs');

class AuthApiService {
    public async login(body: LoginBody) {
        let data = qs.stringify({
            UserName: body.UserName,
            Password: body.Password,
        });
        const httpApi = axios.create({
            baseURL: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
            timeout: 30000,
            paramsSerializer: {
                encode: qs.parse,
                serialize: qs.stringify,
            }
        });
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'api/auth-plugin/auth/login',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        const result = await httpApi.request(config);
        return result.data as CommonResultDto<JwtDto>;
    }

    public async refreshToken() {
        let data = qs.stringify({
            AccessToken: JwtUtils.getToken(),
            RefreshToken: JwtUtils.getRefreshToken(),
        });
        const httpApi = axios.create({
            baseURL: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
            timeout: 30000,
            paramsSerializer: {
                encode: qs.parse,
                serialize: qs.stringify,
            }
        });
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'api/auth-plugin/auth/refresh-token',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        const result = await httpApi.request(config);
        return result.data as CommonResultDto<JwtDto>;
    }
}

export default new AuthApiService();
