import {GlobalOutlined} from "@ant-design/icons";
import {Dropdown, MenuProps} from "antd";
import React from "react";
import {LangUtil} from "@ord-core/language/lang.utils";

interface ILang {
    key: string,
    name: string
}

const SwitchLangue = () => {
    const changeLang = (key: string) => {
        LangUtil.setLang(key);
        window.location.reload();
    }
    const languages: ILang[] = [
        {
            key: 'vn',
            name: 'Tiếng Việt'
        },
        {
            key: 'en',
            name: 'English'
        }
    ]
    const items: MenuProps['items'] = languages.map((it, idx) => {
        return {
            label: (<span className="bold">{it.name}</span>),
            key: idx,
            onClick: () => {
                changeLang(it.key);
            }
        }
    });

    return (<Dropdown menu={{items}} placement='bottomRight'>
        <GlobalOutlined style={{fontSize: '20px', marginRight: '20px', cursor: 'pointer'}}/>
    </Dropdown>)
}
export default SwitchLangue;
