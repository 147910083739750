import {AppBootstrapDto} from "@ord-core/service-proxies/session/dto";


export const checkPermissionUser = (session: AppBootstrapDto, permissionName?: string) => {
    if (session?.isLogined !== true) {
        return false;
    }
    if (!permissionName) {
        return true;
    }
    const {user} = session;
    if (user?.isSuperAdmin === true) {
        return true;
    }
    return session.permissionGranted[permissionName] === true;
}
