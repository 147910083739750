import * as React from 'react';
import {Card, Col, Row} from 'antd';
import DocumentTitle from "react-document-title";
import {Outlet} from "react-router-dom";
import './AuthLayout.scss';


class AuthLayout extends React.Component<any> {

    render() {
        return <DocumentTitle title={'Đăng nhập'}>
            <div className="main-content">
                <div className="container-fluid">
                    <div
                        className="relative main-content pt-[120px] bg-top bg-no-repeat  bg-contain">
                        <div className="admin">
                            <div className="text-center">
                                <a href="/auth/login">
                                    <img className="inline dark:hidden w-[300px] "
                                         src="/images/logo/logo-dark.png"
                                         alt="image"/>
                                    <img className="hidden dark:inline" src="/images/logo/logo-white.png"
                                         alt="image"/>
                                </a>

                            </div>
                            <Row className="justify-center auth-container mt-3">
                                <Col xs={24} sm={18} md={12} lg={8} xl={6}>
                                    <Card className="px-4 pt-0">
                                        <Outlet/>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>


                </div>

            </div>


        </DocumentTitle>
    }
}

export default AuthLayout;
