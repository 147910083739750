import {AppstoreOutlined, HomeOutlined, SettingOutlined, UserOutlined, WindowsOutlined} from "@ant-design/icons";
import {SideNavInterface} from "@ord-core/model/side-nav.type";
import React from "react";
import {PERMISSION_APP} from "@ord-core/config/permissions";

export default [
    {
        path: '/',
        icon: <HomeOutlined/>,
        title: 'menu.home'
    },
    {
        icon: <WindowsOutlined/>,
        title: 'menu.masterData',
        children: [
            {
                path: '/master-data/province',
                permission: PERMISSION_APP.masterData.province,
                title: 'menu.province'
            },
        ]
    },
    {
        icon: <SettingOutlined/>,
        title: 'menu.admin',
        children: [
            {
                path: '/admin/users',
                permission: PERMISSION_APP.admin.user,
                icon: <UserOutlined/>,
                title: 'menu.user'
            },
            {
                path: '/admin/roles',
                icon: <AppstoreOutlined/>,
                permission: PERMISSION_APP.admin.role,
                title: 'menu.role'
            },
            {
                path: '/admin/tenant',
                icon: <AppstoreOutlined/>,
                permission: PERMISSION_APP.admin.tenant,
                title: 'menu.tenant'
            },
            {
                path: '/admin/setting',
                icon: <AppstoreOutlined/>,
                permission: PERMISSION_APP.admin.setting,
                title: 'menu.setting'
            }
        ]
    }
] as SideNavInterface[];
