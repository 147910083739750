import {lazy} from 'react';
import {OrdRouterItem} from "@ord-core/model/ordRouter.model";
import {AdminRouter} from "@pages/Admin/AdminRouter";
import {MasterDataRouter} from "@pages/MasterData/MasterDataRouter";

export const appRouters: OrdRouterItem[] = [
    {
        index: true,
        lazyComponent: lazy(() => import('@pages/Home')),
    },
    ...AdminRouter,
    ...MasterDataRouter
]
