import {
    axios,
    basePath,
    CommonResultDtoOfSettingDto,
    getConfigs,
    IRequestConfig,
    IRequestOptions,
    PagedResultDtoOfSettingDto,
    SettingPagedRequestDto,
    SetValueSettingCommand
} from './index.defs';

export class SettingService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getPaged(
    params: {
      /** requestBody */
      body?: SettingPagedRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PagedResultDtoOfSettingDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/setting/get-paged';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setValue(
    params: {
      /** requestBody */
      body?: SetValueSettingCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommonResultDtoOfSettingDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth-plugin/setting/set-value';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
