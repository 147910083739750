import axios from 'axios';
import JwtUtils from "@ord-core/utils/jwt.utils";
import AuthApiService from "@ord-core/service-proxies/auth/authApiService";
import paths from "@ord-core/config/paths";
import {LangUtil} from "@ord-core/language/lang.utils";

const qs = require('qs');
let isRefreshing = false;
let refreshSubscribers: any[] = [];

const baseHttpApi = axios.create({
    baseURL: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
    timeout: 30000,
    paramsSerializer: {
        encode: qs.parse,
        serialize: qs.stringify,
    }
});

baseHttpApi.interceptors.request.use(
    function (config) {
        const token = window.localStorage.getItem('auth-token');
        if (!!token) {
            // @ts-ignore
            config.headers.common['Authorization'] = 'Bearer ' + token;
        }
        // @ts-ignore
        config.headers.common['Accept-Language'] = LangUtil.getLang();

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

baseHttpApi.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const {config, response: {status}} = error;
        const originalRequest = config;
        // Kiểm tra mã lỗi có phải là 401 hoặc 403 hay không
        if (status === 401 && !!JwtUtils.getRefreshToken()) {
            if (!isRefreshing) {
                isRefreshing = true;
                AuthApiService.refreshToken().then((result) => {
                    if (result.isSuccessful) {
                        const newToken = result.data?.accessToken || ''
                        JwtUtils.saveToken(newToken);
                        JwtUtils.saveRefreshToken(result.data?.refreshToken || '');
                        isRefreshing = false;
                        onRrefreshed(newToken);
                    } else {
                        window.location.href = paths.login;
                    }
                });
            }
            return new Promise((resolve, reject) => {
                subscribeTokenRefresh((token: string) => {
                    // replace the expired token and retry
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    resolve(axios(originalRequest));
                });
            });

        }

        // Nếu không, trả lỗi về điểm cuối đã gọi api
        return Promise.reject(error);
    }
);
const subscribeTokenRefresh = (cb: any) => {
    refreshSubscribers.push(cb);
}

const onRrefreshed = (token: any) => {
    refreshSubscribers.map(cb => cb(token));
}

export default baseHttpApi;
