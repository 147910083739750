import {IRequestOptions, TenantDto, UserDto} from "@api/index.defs";
import {UserService} from "@api/UserService";
import {CommonListStore, ICreateOrUpdateModal} from "@ord-core/base/CommonListStore";
import {CommonCrudApi} from "@ord-core/base/CommonCrudApi";
import {TenantService} from "@api/TenantService";

class TenantListStore extends CommonListStore<TenantDto> {
    getNamespaceLocale(): string {
        return "tenant-list"
    }

    apiService() {
        return {
            getPaged: TenantService.getPaged,
            exportPagedResult: UserService.exportPagedResult,
            createOrUpdate: (params, options: IRequestOptions) => {
                if (this.createOrUpdateModal.mode === 'addNew') {
                    return TenantService.create(params, options);
                }
                return TenantService.update(params, options);
            },
            remove: (params, options) => {
                return TenantService.remove({
                    tenantId: params.removeId
                }, options);
            },
        } as CommonCrudApi<UserDto>;
    }

    getInitModal(): ICreateOrUpdateModal {
        return {
            width: 600
        };
    }

    getListColumnNameExcel(): string[] {
        return ['stt', 'code', 'Name', 'PhoneNumber', 'Email', 'address', 'status']
    }
}

export default TenantListStore;
