import {observer} from "mobx-react";
import SwitchLangue from "@ord-core/layout/Header/SwitchLangue";
import UserTopBar from "@ord-core/layout/Header/UserTopBar";
import React from "react";
import {Space} from "antd";
import './HeaderRight.scss';

const HeaderRight = () => {
    return (
        <div className="header-right">
            <Space>
                <SwitchLangue/>
            </Space>
            <Space>
                <UserTopBar/>
            </Space>

        </div>
    )
}
export default observer(HeaderRight);
