import {action, observable} from "mobx";

class UiStore {
    @observable fullscreenLoading: boolean = false;


    @action
    setBusy() {
        this.fullscreenLoading = true;
    }

    @action
    clearBusy() {
        this.fullscreenLoading = false;
    }

}

export default UiStore;
