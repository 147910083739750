import {OrdRouterItem} from "@ord-core/model/ordRouter.model";
import {lazy} from "react";
import {PERMISSION_APP} from "@ord-core/config/permissions";

export const MasterDataRouter: OrdRouterItem[] = [
    {
        path: '/master-data/province',
        lazyComponent: lazy(() => import('@pages/MasterData/Province')),
        permission: PERMISSION_APP.masterData.province
    }
]
