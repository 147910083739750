import React, {Suspense, useEffect, useState} from 'react';
import './App.scss';
import {ConfigProvider, Spin} from "antd";
import {AntDesignConfig} from "@ord-core/theme/ant-design.config.token";
import {observer} from "mobx-react";
import {AppBootstrapDto} from "@ord-core/service-proxies/session/dto";
import {RouterProvider} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useStore} from "@ord-store/index";
import vi_VN from 'antd/locale/vi_VN';
import {ROOT_ROUTER} from "./Router/router.config";
import {LangUtil} from "@ord-core/language/lang.utils";
import AppCommonEntityFormModal from "@ord-components/crud/AppCommonEntityFormModal";
import Loader from "@ord-components/loader";
import {useTranslation} from "react-i18next";

declare var ord: any;

interface IAppProp {
    boostrapResultDto: AppBootstrapDto;
}

const App = (props: IAppProp) => {
    const {sessionStore, uiStore, entityModalStore} = useStore();
    sessionStore!.setSession(props.boostrapResultDto);
    const [antLocale, setAntLocale] = useState(vi_VN);
    const {t} = useTranslation(['menu', 'permission', 'common']);
    useEffect(() => {
        ord.event.on('event@ord.ui.setBusy', () => {
            uiStore.setBusy();
        });
        ord.event.on('event@ord.ui.clearBusy', () => {
            uiStore.clearBusy();
        });
        // @ts-ignore
        setAntLocale(LangUtil.getAntLocale());
    }, []);

    return (
        <ConfigProvider theme={AntDesignConfig} locale={antLocale}>
            <Suspense fallback={<Spin fullscreen={true}></Spin>}>
                <RouterProvider router={ROOT_ROUTER} fallbackElement={<Spin fullscreen={true}></Spin>}/>
            </Suspense>
            <ToastContainer/>
            <Loader/>
            {/*modal dùng chung cho sửa thông tin */}
            <AppCommonEntityFormModal/>
        </ConfigProvider>
    );
};


export default observer(App);
