import {ADMIN_PERMISSION_TREE_DATA} from "@ord-core/config/permissions/tree-data/admin-permission.tree.data";
import {
    MASTER_DATA_PERMISSION_TREE_DATA
} from "@ord-core/config/permissions/tree-data/master-data-permission.tree.data";

export const PERMISSION_TREE = [
    {
        groupName: 'group-admin',
        items: ADMIN_PERMISSION_TREE_DATA
    },
    {
        groupName: 'group-master-data',
        items: MASTER_DATA_PERMISSION_TREE_DATA
    }
]
