import {OrdPermissionTreeDataNode} from "@ord-core/config/permissions/tree-data/permission.tree.data";
import {l} from "@ord-core/language/lang.utils";
import React from "react";
import {TreeDataNode} from "antd";
import {PERMISSION_TREE} from "@ord-core/config/permissions/tree-data";
import ArrayUtil from "@ord-core/utils/array/array.util";

class OrdPermissionArrayUtil {
    private listPermissionBase: string[] = [];
    private _root: TreeDataNode[] | undefined;

    getTreeDataRoot(): TreeDataNode[] {
        if (this._root) {
            return this._root;
        }
        this.listPermissionBase = [];
        this._root = [
            {
                title: l.trans('permission.All'),
                key: 'root',
                children: PERMISSION_TREE.map((it, idx) => {
                    const nodes = this.getNodes(it.items);
                    const treePermission = ArrayUtil.arrToTreeNode(nodes, {
                        idMapName: 'name',
                        parentIdMapName: 'parentName',
                        titleMapName: 'title',
                    });
                    return {
                        title: l.trans('permission.group.' + it.groupName),
                        key: it.groupName,
                        children: [...treePermission]
                    };
                })
            }
        ];
        return this._root;
    }

    private getNodes = (items: OrdPermissionTreeDataNode[]) => {
        const flatNode = this.flattenMenu(items);
        flatNode.forEach(it => {
            if (it?.actions?.length > 0) {
                this.listPermissionBase.push(it.name);
                it.actions.forEach((actionName: string) => {
                    flatNode.push({
                        name: it.name + '.' + actionName,
                        parentName: it.name,
                        title: (<>
                            {l.trans('permission.action.' + actionName)}
                            <b className="ms-1">({l.trans('permission.' + it?.name)})</b>
                        </>),
                        expanded: false
                    });
                });
                it.expanded = false;
            }
        });
        flatNode.forEach(it => {
            if (!it?.title) {
                it.title = l.trans('permission.' + it?.name);
                if (it?.expanded !== false) {
                    it.expanded = true;
                }
            }
        });
        return flatNode;
    }
    private flattenMenu = (menu: OrdPermissionTreeDataNode[], parentName = null) => {
        const result: any[] = [];

        function flatten(items: any, parentName: any) {
            for (const item of items) {
                const {children, ...rest} = item;
                result.push({...rest, parentName});
                if (children) {
                    flatten(children, rest.name);
                }
            }
        }

        flatten(menu, parentName);
        return result;
    }

    includePermissionBase(listPermissionName: string[]) {
        this.listPermissionBase.forEach(it => {
            if (!listPermissionName.includes(it)) {
                const existAction = listPermissionName.find(x => x.includes(it));
                if (existAction) {
                    listPermissionName.push(it);
                }
            }
        });
    }

    ignorePermissionBaseWhenSetGranted(granted: string[]) {
        if (granted) {
            try {
                return granted?.filter(x => !this.listPermissionBase.includes(x)) || [];
            } catch {

            }

        }
        return [];
    }

}

export default new OrdPermissionArrayUtil();
