/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import {type AxiosInstance, type AxiosRequestConfig} from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /**
   * show loading status
   */
  loading?: boolean;
  /**
   * display error message
   */
  showError?: boolean;
  /**
   * data security, extended fields are encrypted using the specified algorithm
   */
  security?: Record<string, 'md5' | 'sha1' | 'aes' | 'des'>;
  /**
   * indicates whether Authorization credentials are required for the request
   * @default true
   */
  withAuthorization?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export interface ActionApiDescriptionModel {
  /**  */
  uniqueName?: string;

  /**  */
  name?: string;

  /**  */
  httpMethod?: string;

  /**  */
  url?: string;

  /**  */
  supportedVersions?: string[];

  /**  */
  parametersOnMethod?: MethodParameterApiDescriptionModel[];

  /**  */
  parameters?: ParameterApiDescriptionModel[];

  /**  */
  returnValue?: ReturnValueApiDescriptionModel;

  /**  */
  allowAnonymous?: boolean;

  /**  */
  implementFrom?: string;
}

export interface AppBootstrapDto {
  /**  */
  setting?: object;

  /**  */
  user?: UserInformationDto;

  /**  */
  isLogined?: boolean;
}

export interface ApplicationApiDescriptionModel {
  /**  */
  modules?: object;

  /**  */
  types?: object;
}

export interface ApplicationAuthConfigurationDto {
  /**  */
  grantedPolicies?: object;
}

export interface ApplicationConfigurationDto {
  /**  */
  localization?: ApplicationLocalizationConfigurationDto;

  /**  */
  auth?: ApplicationAuthConfigurationDto;

  /**  */
  setting?: ApplicationSettingConfigurationDto;

  /**  */
  currentUser?: CurrentUserDto;

  /**  */
  features?: ApplicationFeatureConfigurationDto;

  /**  */
  globalFeatures?: ApplicationGlobalFeatureConfigurationDto;

  /**  */
  multiTenancy?: MultiTenancyInfoDto;

  /**  */
  currentTenant?: CurrentTenantDto;

  /**  */
  timing?: TimingDto;

  /**  */
  clock?: ClockDto;

  /**  */
  objectExtensions?: ObjectExtensionsDto;

  /**  */
  extraProperties?: object;
}

export interface ApplicationFeatureConfigurationDto {
  /**  */
  values?: object;
}

export interface ApplicationGlobalFeatureConfigurationDto {
  /**  */
  enabledFeatures?: string[];
}

export interface ApplicationLocalizationConfigurationDto {
  /**  */
  values?: object;

  /**  */
  resources?: object;

  /**  */
  languages?: LanguageInfo[];

  /**  */
  currentCulture?: CurrentCultureDto;

  /**  */
  defaultResourceName?: string;

  /**  */
  languagesMap?: object;

  /**  */
  languageFilesMap?: object;
}

export interface ApplicationLocalizationDto {
  /**  */
  resources?: object;

  /**  */
  currentCulture?: CurrentCultureDto;
}

export interface ApplicationLocalizationResourceDto {
  /**  */
  texts?: object;

  /**  */
  baseResources?: string[];
}

export interface ApplicationSettingConfigurationDto {
  /**  */
  values?: object;
}

export interface ChangePasswordCommand {
  /**  */
  currentPassword?: string;

  /**  */
  newPassword?: string;
}

export interface CheckCodeForgotPasswordQuery {
  /**  */
  code?: string;
}

export interface ClockDto {
  /**  */
  kind?: string;
}

export interface ComboOptionDto {
  /**  */
  value?: any | null;

  /**  */
  displayName?: string;

  /**  */
  data?: any | null;
}

export interface CommonResultDtoOfBoolean {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: boolean;
}

export interface CommonResultDtoOfDanTocDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: DanTocDto;
}

export interface CommonResultDtoOfHuyenDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: HuyenDto;
}

export interface CommonResultDtoOfIcd10Dto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: Icd10Dto;
}

export interface CommonResultDtoOfInt32 {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: number;
}

export interface CommonResultDtoOfJwtDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: JwtDto;
}

export interface CommonResultDtoOfQuocGiaDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: QuocGiaDto;
}

export interface CommonResultDtoOfRoleDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: RoleDto;
}

export interface CommonResultDtoOfSettingDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: SettingDto;
}

export interface CommonResultDtoOfTenantDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: TenantDto;
}

export interface CommonResultDtoOfThonApDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: ThonApDto;
}

export interface CommonResultDtoOfTinhDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: TinhDto;
}

export interface CommonResultDtoOfUserBaseDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: UserBaseDto;
}

export interface CommonResultDtoOfUserDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: UserDto;
}

export interface CommonResultDtoOfXaDto {
  /**  */
  isSuccessful?: boolean;

  /**  */
  errorDetail?: CommonResultExtendDto;

  /**  */
  notification?: CommonResultExtendDto;

  /**  */
  errors?: ValidateInputDto[];

  /**  */
  data?: XaDto;
}

export interface CommonResultExtendDto {
  /**  */
  code?: string;

  /**  */
  message?: string;

  /**  */
  data?: any | null;
}

export interface ControllerApiDescriptionModel {
  /**  */
  controllerName?: string;

  /**  */
  controllerGroupName?: string;

  /**  */
  isRemoteService?: boolean;

  /**  */
  isIntegrationService?: boolean;

  /**  */
  apiVersion?: string;

  /**  */
  type?: string;

  /**  */
  interfaces?: ControllerInterfaceApiDescriptionModel[];

  /**  */
  actions?: object;
}

export interface ControllerInterfaceApiDescriptionModel {
  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  methods?: InterfaceMethodApiDescriptionModel[];
}

export interface CreateUserCommand {
  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  name?: string;

  /**  */
  isActived?: boolean;

  /**  */
  level?: string;

  /**  */
  isLockoutEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  dynamicInformation?: object;

  /**  */
  listRoleId?: string[];

  /**  */
  extendData?: any | null;

  /**  */
  mustChangePassword?: boolean;

  /**  */
  lastModificationTime?: Date;

  /**  */
  birthDay?: Date;

  /**  */
  password?: string;
}

export interface CurrentCultureDto {
  /**  */
  displayName?: string;

  /**  */
  englishName?: string;

  /**  */
  threeLetterIsoLanguageName?: string;

  /**  */
  twoLetterIsoLanguageName?: string;

  /**  */
  isRightToLeft?: boolean;

  /**  */
  cultureName?: string;

  /**  */
  name?: string;

  /**  */
  nativeName?: string;

  /**  */
  dateTimeFormat?: DateTimeFormatDto;
}

export interface CurrentTenantDto {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  isAvailable?: boolean;
}

export interface CurrentUserDto {
  /**  */
  isAuthenticated?: boolean;

  /**  */
  id?: string;

  /**  */
  tenantId?: string;

  /**  */
  impersonatorUserId?: string;

  /**  */
  impersonatorTenantId?: string;

  /**  */
  impersonatorUserName?: string;

  /**  */
  impersonatorTenantName?: string;

  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surName?: string;

  /**  */
  email?: string;

  /**  */
  emailVerified?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberVerified?: boolean;

  /**  */
  roles?: string[];

  /**  */
  sessionId?: string;
}

export interface DanTocDto {
  /**  */
  id?: number;

  /**  */
  ma?: string;

  /**  */
  ten?: string;

  /**  */
  tenKhac?: string;

  /**  */
  isActived?: boolean;
}

export interface DateRangeDto {
  /**  */
  startDate?: Date;

  /**  */
  endDate?: Date;
}

export interface DateTimeFormatDto {
  /**  */
  calendarAlgorithmType?: string;

  /**  */
  dateTimeFormatLong?: string;

  /**  */
  shortDatePattern?: string;

  /**  */
  fullDateTimePattern?: string;

  /**  */
  dateSeparator?: string;

  /**  */
  shortTimePattern?: string;

  /**  */
  longTimePattern?: string;
}

export interface EntityExtensionDto {
  /**  */
  properties?: object;

  /**  */
  configuration?: object;
}

export interface ExtensionEnumDto {
  /**  */
  fields?: ExtensionEnumFieldDto[];

  /**  */
  localizationResource?: string;
}

export interface ExtensionEnumFieldDto {
  /**  */
  name?: string;

  /**  */
  value?: any | null;
}

export interface ExtensionPropertyApiCreateDto {
  /**  */
  isAvailable?: boolean;
}

export interface ExtensionPropertyApiDto {
  /**  */
  onGet?: ExtensionPropertyApiGetDto;

  /**  */
  onCreate?: ExtensionPropertyApiCreateDto;

  /**  */
  onUpdate?: ExtensionPropertyApiUpdateDto;
}

export interface ExtensionPropertyApiGetDto {
  /**  */
  isAvailable?: boolean;
}

export interface ExtensionPropertyApiUpdateDto {
  /**  */
  isAvailable?: boolean;
}

export interface ExtensionPropertyAttributeDto {
  /**  */
  typeSimple?: string;

  /**  */
  config?: object;
}

export interface ExtensionPropertyDto {
  /**  */
  type?: string;

  /**  */
  typeSimple?: string;

  /**  */
  displayName?: LocalizableStringDto;

  /**  */
  api?: ExtensionPropertyApiDto;

  /**  */
  ui?: ExtensionPropertyUiDto;

  /**  */
  attributes?: ExtensionPropertyAttributeDto[];

  /**  */
  configuration?: object;

  /**  */
  defaultValue?: any | null;
}

export interface ExtensionPropertyUiDto {
  /**  */
  onTable?: ExtensionPropertyUiTableDto;

  /**  */
  onCreateForm?: ExtensionPropertyUiFormDto;

  /**  */
  onEditForm?: ExtensionPropertyUiFormDto;

  /**  */
  lookup?: ExtensionPropertyUiLookupDto;
}

export interface ExtensionPropertyUiFormDto {
  /**  */
  isVisible?: boolean;
}

export interface ExtensionPropertyUiLookupDto {
  /**  */
  url?: string;

  /**  */
  resultListPropertyName?: string;

  /**  */
  displayPropertyName?: string;

  /**  */
  valuePropertyName?: string;

  /**  */
  filterParamName?: string;
}

export interface ExtensionPropertyUiTableDto {
  /**  */
  isVisible?: boolean;
}

export interface GetListIcd10Input {
  /**  */
  version?: number;

  /**  */
  typeEnum?: number;

  /**  */
  parentCode?: string;

  /**  */
  isActived?: boolean;
}

export interface GetListPermissionQuery {
  /**  */
  listRoleId?: string[];
}

export interface GetListUserAssignByRoleQuery {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;

  /**  */
  roleId?: string;
}

export interface GetUserByIdQuery {
  /**  */
  userId?: string;
}

export interface HuyenDto {
  /**  */
  id?: number;

  /**  */
  ma?: string;

  /**  */
  ten?: string;

  /**  */
  maTinh?: string;

  /**  */
  tenTinh?: string;

  /**  */
  isActived?: boolean;
}

export interface HuyenPagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;

  /**  */
  maTinh?: string;
}

export interface IanaTimeZone {
  /**  */
  timeZoneName?: string;
}

export interface Icd10Dto {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  parentCode?: string;

  /**  */
  ten?: string;

  /**  */
  name?: string;

  /**  */
  version?: number;

  /**  */
  typeEnum?: number;

  /**  */
  isNodeLeaft?: boolean;

  /**  */
  isActived?: boolean;

  /**  */
  fullPathCode?: string;
}

export interface Icd10PagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;

  /**  */
  version?: number;

  /**  */
  parentCode?: string;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  typeEnum?: number;
}

export interface InterfaceMethodApiDescriptionModel {
  /**  */
  name?: string;

  /**  */
  parametersOnMethod?: MethodParameterApiDescriptionModel[];

  /**  */
  returnValue?: ReturnValueApiDescriptionModel;
}

export interface JwtDto {
  /**  */
  accessToken?: string;

  /**  */
  expireInSeconds?: number;

  /**  */
  refreshToken?: string;
}

export interface LanguageInfo {
  /**  */
  cultureName?: string;

  /**  */
  uiCultureName?: string;

  /**  */
  displayName?: string;

  /**  */
  twoLetterISOLanguageName?: string;
}

export interface LocalizableStringDto {
  /**  */
  name?: string;

  /**  */
  resource?: string;
}

export interface LoginPasswordlessQuery {
  /**  */
  id?: string;
}

export interface MethodParameterApiDescriptionModel {
  /**  */
  name?: string;

  /**  */
  typeAsString?: string;

  /**  */
  type?: string;

  /**  */
  typeSimple?: string;

  /**  */
  isOptional?: boolean;

  /**  */
  defaultValue?: any | null;
}

export interface ModuleApiDescriptionModel {
  /**  */
  rootPath?: string;

  /**  */
  remoteServiceName?: string;

  /**  */
  controllers?: object;
}

export interface ModuleExtensionDto {
  /**  */
  entities?: object;

  /**  */
  configuration?: object;
}

export interface MultiTenancyInfoDto {
  /**  */
  isEnabled?: boolean;
}

export interface NameValue {
  /**  */
  name?: string;

  /**  */
  value?: string;
}

export interface ObjectExtensionsDto {
  /**  */
  modules?: object;

  /**  */
  enums?: object;
}

export interface OrdExportPaged {
  /**  */
  title?: string;

  /**  */
  columnNames?: string[];
}

export interface OrdPagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;
}

export interface PagedResultDtoOfDanTocDto {
  /**  */
  items?: DanTocDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfHuyenDto {
  /**  */
  items?: HuyenDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfIcd10Dto {
  /**  */
  items?: Icd10Dto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfQuocGiaDto {
  /**  */
  items?: QuocGiaDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfRoleDto {
  /**  */
  items?: RoleDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfRoleGetUserPagedDto {
  /**  */
  items?: RoleGetUserPagedDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfSettingDto {
  /**  */
  items?: SettingDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfTenantDto {
  /**  */
  items?: TenantDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfThonApDto {
  /**  */
  items?: ThonApDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfTinhDto {
  /**  */
  items?: TinhDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfUserDto {
  /**  */
  items?: UserDto[];

  /**  */
  totalCount?: string;
}

export interface PagedResultDtoOfXaDto {
  /**  */
  items?: XaDto[];

  /**  */
  totalCount?: string;
}

export interface ParameterApiDescriptionModel {
  /**  */
  nameOnMethod?: string;

  /**  */
  name?: string;

  /**  */
  jsonName?: string;

  /**  */
  type?: string;

  /**  */
  typeSimple?: string;

  /**  */
  isOptional?: boolean;

  /**  */
  defaultValue?: any | null;

  /**  */
  constraintTypes?: string[];

  /**  */
  bindingSourceId?: string;

  /**  */
  descriptorName?: string;
}

export interface PropertyApiDescriptionModel {
  /**  */
  name?: string;

  /**  */
  jsonName?: string;

  /**  */
  type?: string;

  /**  */
  typeSimple?: string;

  /**  */
  isRequired?: boolean;

  /**  */
  minLength?: number;

  /**  */
  maxLength?: number;

  /**  */
  minimum?: string;

  /**  */
  maximum?: string;

  /**  */
  regex?: string;
}

export interface QuocGiaDto {
  /**  */
  id?: number;

  /**  */
  ma?: string;

  /**  */
  ten?: string;

  /**  */
  tenTiengAnh?: string;

  /**  */
  isActived?: boolean;
}

export interface QuocGiaPagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;
}

export interface RemoteServiceErrorInfo {
  /**  */
  code?: string;

  /**  */
  message?: string;

  /**  */
  details?: string;

  /**  */
  data?: object;

  /**  */
  validationErrors?: RemoteServiceValidationErrorInfo[];
}

export interface RemoteServiceErrorResponse {
  /**  */
  error?: RemoteServiceErrorInfo;
}

export interface RemoteServiceValidationErrorInfo {
  /**  */
  message?: string;

  /**  */
  members?: string[];
}

export interface RemoveUserCommand {
  /**  */
  id?: string;
}

export interface ResetPasswordCommand {
  /**  */
  userId?: string;

  /**  */
  newPassword?: string;

  /**  */
  mustChangePassword?: boolean;
}

export interface ReturnValueApiDescriptionModel {
  /**  */
  type?: string;

  /**  */
  typeSimple?: string;
}

export interface RoleDto {
  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  isActived?: boolean;

  /**  */
  listPermissionName?: string[];
}

export interface RoleGetUserPagedDto {
  /**  */
  userId?: string;

  /**  */
  name?: string;

  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  isActived?: boolean;
}

export interface RolePagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;

  /**  */
  isIncludeListPermission?: boolean;
}

export interface RoleSetPermissionCommand {
  /**  */
  roleId?: string;

  /**  */
  listOfPermission?: string[];
}

export interface SendEmailForgotPasswordQuery {
  /**  */
  email?: string;
}

export interface SetValueSettingCommand {
  /**  */
  name?: string;

  /**  */
  value?: string;

  /**  */
  mustEncrypt?: boolean;

  /**  */
  isActived?: boolean;

  /**  */
  type?: SettingType;
}

export interface SettingDto {
  /**  */
  id?: string;

  /**  */
  tenantId?: string;

  /**  */
  userId?: string;

  /**  */
  name?: string;

  /**  */
  value?: string;

  /**  */
  rawValue?: string;

  /**  */
  mustEncrypt?: boolean;

  /**  */
  isActived?: boolean;

  /**  */
  type?: SettingType;
}

export interface SettingPagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;

  /**  */
  type?: SettingType;
}

export interface TenantCreateCommand {
  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  isActived?: boolean;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  address?: string;

  /**  */
  userNameAdminTenant?: string;

  /**  */
  passwordAdminTenant?: string;

  /**  */
  emailAdminTenant?: string;
}

export interface TenantDto {
  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  name?: string;

  /**  */
  isActived?: boolean;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  address?: string;
}

export interface ThonApDto {
  /**  */
  id?: number;

  /**  */
  ma?: string;

  /**  */
  ten?: string;

  /**  */
  maTinh?: string;

  /**  */
  maHuyen?: string;

  /**  */
  maXa?: string;

  /**  */
  isActived?: boolean;

  /**  */
  tenTinh?: string;

  /**  */
  tenHuyen?: string;

  /**  */
  tenXa?: string;
}

export interface ThonApPagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;

  /**  */
  maTinh?: string;

  /**  */
  maHuyen?: string;

  /**  */
  maXa?: string;
}

export interface TimeZone {
  /**  */
  iana?: IanaTimeZone;

  /**  */
  windows?: WindowsTimeZone;
}

export interface TimingDto {
  /**  */
  timeZone?: TimeZone;
}

export interface TinhDto {
  /**  */
  id?: number;

  /**  */
  ma?: string;

  /**  */
  ten?: string;

  /**  */
  isActived?: boolean;
}

export interface TinhPagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;
}

export interface TypeApiDescriptionModel {
  /**  */
  baseType?: string;

  /**  */
  isEnum?: boolean;

  /**  */
  enumNames?: string[];

  /**  */
  enumValues?: any | null[];

  /**  */
  genericArguments?: string[];

  /**  */
  properties?: PropertyApiDescriptionModel[];
}

export interface UnassignUserCommand {
  /**  */
  userId?: string;

  /**  */
  roleId?: string;
}

export interface UpdateUserCommand {
  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  name?: string;

  /**  */
  isActived?: boolean;

  /**  */
  level?: string;

  /**  */
  isLockoutEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  dynamicInformation?: object;

  /**  */
  listRoleId?: string[];

  /**  */
  extendData?: any | null;

  /**  */
  mustChangePassword?: boolean;

  /**  */
  lastModificationTime?: Date;

  /**  */
  birthDay?: Date;

  /**  */
  id?: string;

  /**  */
  password?: string;
}

export interface UpdateUserInformationCommand {
  /**  */
  name?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  extendData?: any | null;
}

export interface UserAssignOrUnassignOneRoleCommand {
  /**  */
  userId?: string;

  /**  */
  roleId?: string;

  /**  */
  isNewAssign?: boolean;
}

export interface UserAssignRoleCommand {
  /**  */
  userId?: string;

  /**  */
  listRoleId?: string[];

  /**  */
  listPermission?: string[];
}

export interface UserBaseDto {
  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  name?: string;

  /**  */
  isActived?: boolean;

  /**  */
  level?: string;

  /**  */
  isLockoutEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  dynamicInformation?: object;

  /**  */
  listRoleId?: string[];

  /**  */
  extendData?: any | null;

  /**  */
  mustChangePassword?: boolean;

  /**  */
  lastModificationTime?: Date;

  /**  */
  birthDay?: Date;
}

export interface UserDto {
  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  name?: string;

  /**  */
  isActived?: boolean;

  /**  */
  level?: string;

  /**  */
  isLockoutEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  dynamicInformation?: object;

  /**  */
  listRoleId?: string[];

  /**  */
  extendData?: any | null;

  /**  */
  mustChangePassword?: boolean;

  /**  */
  lastModificationTime?: Date;

  /**  */
  birthDay?: Date;

  /**  */
  id?: string;
}

export interface UserGetPagedQuery {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  export?: OrdExportPaged;

  /**  */
  extendFilter?: any | null;

  /**  */
  level?: string;

  /**  */
  isActived?: boolean;

  /**  */
  userName?: string;

  /**  */
  birthDay?: DateRangeDto;
}

export interface UserInformationDto {
  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  name?: string;

  /**  */
  isActived?: boolean;

  /**  */
  level?: string;

  /**  */
  isLockoutEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  dynamicInformation?: object;

  /**  */
  listRoleId?: string[];

  /**  */
  extendData?: any | null;

  /**  */
  mustChangePassword?: boolean;

  /**  */
  lastModificationTime?: Date;

  /**  */
  birthDay?: Date;

  /**  */
  id?: string;

  /**  */
  tenantId?: string;

  /**  */
  tenantDto?: any | null;

  /**  */
  isSuperAdmin?: boolean;

  /**  */
  listPermission?: string[];
}

export interface ValidateInputDto {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;

  /**  */
  errorCode?: string;
}

export interface WindowsTimeZone {
  /**  */
  timeZoneId?: string;
}

export interface XaDto {
  /**  */
  id?: number;

  /**  */
  ma?: string;

  /**  */
  ten?: string;

  /**  */
  maTinh?: string;

  /**  */
  maHuyen?: string;

  /**  */
  isActived?: boolean;

  /**  */
  tenTinh?: string;

  /**  */
  tenHuyen?: string;
}

export interface XaPagedRequestDto {
  /**  */
  maxResultCount?: number;

  /**  */
  skipCount?: number;

  /**  */
  sorting?: string;

  /**  */
  filter?: string;

  /**  */
  isActived?: boolean;

  /**  */
  export?: OrdExportPaged;

  /**  */
  maTinh?: string;

  /**  */
  maHuyen?: string;
}

export type SettingType = 1 | 2 | 3;
