import {OrdRouterItem} from "@ord-core/model/ordRouter.model";
import {lazy} from "react";
import {PERMISSION_APP} from "@ord-core/config/permissions";

export const AdminRouter: OrdRouterItem[] = [
    {
        path: '/admin/users',
        lazyComponent: lazy(() => import('@pages/Admin/Users')),
        permission: PERMISSION_APP.admin.user
    },
    {
        path: '/admin/roles',
        lazyComponent: lazy(() => import('@pages/Admin/Roles')),
        permission: PERMISSION_APP.admin.role
    },
    {
        path: '/admin/tenant',
        lazyComponent: lazy(() => import('@pages/Admin/Tenants')),
        permission: PERMISSION_APP.admin.tenant
    },
    {
        path: '/admin/setting',
        lazyComponent: lazy(() => import('@pages/Admin/Setting')),
        permission: PERMISSION_APP.admin.setting
    }
]
